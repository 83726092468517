import React from "react";
import "./Certifications.css";
import { Fade } from "react-reveal";
import { certifications } from "../../portfolio";
import CertificationCard from "../../components/certificationCard/CertificationCard";
import "lightgallery.js/dist/css/lightgallery.css";
import {
  LightgalleryProvider,
  LightgalleryItem,
  withLightgallery,
  useLightgallery,
} from "react-lightgallery";
function Certifications(props) {
  const theme = props.theme;
  const [toggle, setToggle] = React.useState(false);
  const [sIndex, setSIndex] = React.useState(0);
  const lightBoxHandler = (state, sIndex) => {
    setToggle(state);
    setSIndex(sIndex);
  };
  //  const onBeforeSlide = (detail) => {
  //    const { index, prevIndex } = detail;
  //    console.log(index, prevIndex);
  //  };
  return (
    <div className="main" id="certs">
      <div className="certs-header-div" style={{ marginTop: "5rem" }}>
        <Fade bottom duration={2000} distance="20px">
          <h1 className="certs-header" style={{ color: theme.text }}>
            Certifications
          </h1>
        </Fade>
      </div>
      <LightgalleryProvider>
        <div className="certs-body-div" style={{ marginTop: "5rem" }}>
          {certifications.certifications.map((cert, index) => {
            return (
              <CertificationCard
                onClick={() => lightBoxHandler(true, index)}
                key={index}
                certificate={cert}
                theme={theme}
              />
            );
          })}
        </div>
      </LightgalleryProvider>
    </div>
  );
}

export default Certifications;
