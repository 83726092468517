import SkillCard from "../../components/SkillCard/SkillCard";
import "./Skills.css";
const SkillsSection = ({ sections, theme }) => {
  return (
    <div>
      <div className="skills-body-div">
        {sections.map((skill, indx) => (
          <SkillCard skill={skill} theme={theme} key={indx} />
        ))}
      </div>
    </div>
  );
};

export default SkillsSection;
