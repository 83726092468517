import React from "react";
import "./Footer.css";
import { Fade } from "react-reveal";
import { greeting } from "../../portfolio.js";
/* eslint-disable jsx-a11y/accessible-emoji */

export default function Footer(props) {
  return (
    <div className="footer-div">
      <Fade>
        <p className="footer-text" style={{ color: props.theme.secondaryText }}>
          Made by
          <a
            target="_blank"
            href={greeting.link}
            style={{
              textDecoration: "none",
              color: props.theme.secondaryText,
              marginLeft: "0.2rem",
            }}
          >
            {greeting.title2}
          </a>
        </p>
      </Fade>
    </div>
  );
}
