import React from "react";
import achievementImage from "../../assests/images/achievements pic.png";
export default function EducationImg(props) {
  const theme = props.theme;
  return (
    <div>
      <img
        style={{ width: "90%", height: "90%", objectFit: "cover" }}
        src={achievementImage}
        alt="achievementImage"
      />
    </div>
  );
}
