import React from "react";
import "./CertificationCard.css";
import { Fade } from "react-reveal";
import { style } from "glamor";
import { LightgalleryItem } from "react-lightgallery";
import image from "../../assests/images/100.jpg";
function CertificationCard(props) {
  const certificate = props.certificate;
  const theme = props.theme;
  const styles = style({
    boxShadow: `0px 2px 5px #c5e2ee`,
    ":hover": {
      boxShadow: `0 5px 15px #c5e2ee`,
    },
  });

  return (
    <Fade bottom duration={2000} distance="20px">
      <div className="cert-card" {...styles}>
        <LightgalleryItem
          group={""}
          subHtml={`<h2>${certificate.title}</h2>`}
          src={certificate.image}
        >
          <div className="content">
            <div
              className="cert-header"
              style={{
                width: "100%",
                height: "200px",
                backgroundImage: `url(${certificate?.image})`,
                backgroundPosition: "center -25px",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
            ></div>
          </div>
          <div className="cert-body" style={{ padding: "0.5rem" }}>
            <h3
              className="cert-body-title"
              style={{ color: theme.text, fontWeight: 400 }}
            >
              {certificate.title}
            </h3>
          </div>
        </LightgalleryItem>
      </div>
    </Fade>
  );
}

export default CertificationCard;
