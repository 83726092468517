import React from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import ExperienceAccordion from "../../containers/experienceAccordion/ExperienceAccordion.js";
import "./Experience.css";
import { skillsPage } from "../../portfolio.js";
import { Fade } from "react-reveal";
import ExperienceImg from "./SkillsImg";
import SkillsSection from "../../containers/SkillsSection/SkillsSection";
import ManagementSkills from "./ManagementSkills.js";

function Skills(props) {
  const theme = props.theme;
  console.log(props.setTheme);
  return (
    <div className="experience-main">
      <Header theme={theme} setTheme={props.setTheme} />
      <div className="basic-experience">
        <Fade bottom duration={2000} distance="40px">
          <div className="experience-heading-div">
            <div className="experience-heading-img-div">
              <ExperienceImg theme={theme} />
            </div>
            <div className="experience-heading-text-div">
              <h1
                className="experience-heading-text"
                style={{ color: theme.text }}
              >
                {skillsPage.title}
              </h1>
              <h6
                className="experience-heading-sub-text"
                style={{ color: theme.text, lineHeight: "30px" }}
              >
                {skillsPage["subtitle"]}
              </h6>
            </div>
          </div>
        </Fade>
      </div>
      <div>
        <h5
          className="experience-heading-sub-text"
          style={{
            marginBottom: "2rem",
            color: theme.text,
            lineHeight: "30px",
          }}
        >
          Management Skills
        </h5>
        <ManagementSkills
          theme={theme}
          sections={skillsPage["managerSkills"]}
        />
      </div>
      <div>
        <h5
          className="experience-heading-sub-text"
          style={{
            marginBottom: "2rem",
            color: theme.text,
            lineHeight: "30px",
          }}
        >
          Software Skills
        </h5>
        <SkillsSection theme={theme} sections={skillsPage["sections"]} />
      </div>

      <Footer theme={props.theme} onToggle={props.onToggle} />
    </div>
  );
}

export default Skills;
