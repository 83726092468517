import React from "react";
import ProjectLanguages from "../projectLanguages/ProjectLanguages";
import "./ProjectCard.css";
import { Fade } from "react-reveal";
import { style } from "glamor";

export default function ProjectCard({ repo: project, theme }) {
  function openRepoinNewTab(url) {
    var win = window.open(url, "_blank");
    win.focus();
  }

  const styles = style({
    color: "rgb(88, 96, 105)",
    backgroundColor: "rgb(255, 255, 255)",
    boxShadow: "rgba(0, 0, 0, 0.2) 0px 10px 30px -15px",
    padding: "2rem",
    cursor: "pointer",
    borderRadius: "1.2rem",
    height: "100%",
    transition: "all 0.2s ease-in-out",
    ":hover": {
      boxShadow: `${theme.imageDark} 0 2px 15px`,
    },
    height: "380px",
  });
  console.log(`../../assests/images/${project?.imageUrl}`);
  return (
    <div>
      <Fade bottom duration={2000} distance="40px">
        <div
          {...styles}
          key={project.name}
          style={{
            backgroundColor: theme.projectCard,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            gap: "0.5rem",
            cursor: "auto",
          }}
        >
          <div className="repo-name-div">
            <p className="repo-name" style={{ color: theme.text }}>
              {project.name}
            </p>
          </div>
          <p
            className="repo-description"
            style={{
              color: theme.text,
              textAlign: "center",
              fontSize: "16px",
              color: theme.text,
            }}
          >
            {project.description}
          </p>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={project.url}
            style={{ color: theme.secondaryText, textDecoration: "none" }}
          >
            {project.url_name}
          </a>
          <div className="repo-details">
            <img
              src={require(`../../assests/images/${
                project?.imageUrl ?? "docker.png"
              }`)}
            />
          </div>
        </div>
      </Fade>
    </div>
  );
}
