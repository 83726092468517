import React from "react";
import "./Skills.css";
import SoftwareSkill from "../../components/softwareSkills/SoftwareSkill";
import { skills } from "../../portfolio";
import { Fade } from "react-reveal";
import DataScienceImg from "./DataScienceImg";
import FullStackImg from "./FullStackImg";
import CloudInfraImg from "./CloudInfraImg";
import { required } from "glamor";
import HtmlReactParsers from "html-react-parser";
// import DesignImg from "./DesignImg";
import { style } from "glamor";

function GetSkillSvg(props) {
  if (props.fileName === "DataScienceImg")
    return <FullStackImg theme={props.theme} />;
  else if (props.fileName === "FullStackImg")
    return (
      <img
        style={{
          width: "110%",
          height: "110%",
          objectFit: "contain",
          marginBottom: "2rem",
        }}
        src={require("../../assests/images/erfani portfolio landing pic.png")}
      />
    );
  else if (props.fileName === "CloudInfraImg")
    return (
      <img
        loading="lazy"
        style={{ width: "100%", height: "80%", objectFit: "contain" }}
        src={require("../../assests/images/startups pic.png")}
      />
    );
}

function SkillSection(props) {
  const theme = props.theme;
  const styles = style({
    "@media (max-width: 768px)": {
      flexDirection: "column-reverse",
    },
  });
  const title = style({
    display: "none",
    "@media (max-width: 768px)": {
      display: "flex",
    },
  });
  const firstTitle = style({
    display: "flex",
    "@media (max-width: 768px)": {
      display: "none",
    },
  });
  return (
    <div className="">
      {skills.data.map((skill, index) => {
        if (index % 2 === 0) {
          return (
            <div>
              {skill?.title === "Foundations & StartUps" && (
                <div {...title} className="top-title">
                  {skill.title && (
                    <Fade right duration={1000}>
                      <h1
                        className="skills-heading"
                        style={{ color: theme.text, textAlign: "left" }}
                      >
                        {skill.title}
                      </h1>
                    </Fade>
                  )}
                </div>
              )}
              <div key={skill.title} className="skills-main-div">
                <Fade left duration={2000}>
                  <div className="skills-image-div">
                    <GetSkillSvg fileName={skill.fileName} theme={theme} />
                  </div>
                </Fade>

                <div className="skills-text-div" {...styles}>
                  {skill.title && (
                    <Fade right duration={1000}>
                      <h1
                        {...firstTitle}
                        className="skills-heading"
                        style={{ color: theme.text, textAlign: "left" }}
                      >
                        {skill.title}
                      </h1>
                    </Fade>
                  )}

                  <Fade right duration={2000}>
                    <div className="skillSentence">
                      {skill.skills.map((skillSentence) => {
                        return (
                          <p
                            key={skillSentence}
                            className=" skills-text"
                            style={{ color: theme.secondaryText }}
                          >
                            {HtmlReactParsers(String(skillSentence))}
                          </p>
                        );
                      })}
                    </div>
                  </Fade>
                </div>
              </div>
            </div>
          );
        } else {
          return (
            <div key={skill.title} className="skills-main-div">
              <div className="skills-text-div">
                <Fade left duration={1000}>
                  <h1
                    {...firstTitle}
                    className="skills-heading"
                    style={{ color: theme.text, textAlign: "left" }}
                  >
                    {skill.title}
                  </h1>
                </Fade>

                <Fade left duration={2000}>
                  <div className="skillSentence">
                    {skill.skills.map((skillSentence) => {
                      return (
                        <p key={skillSentence} className="subTitle skills-text">
                          {HtmlReactParsers(String(skillSentence))}
                        </p>
                      );
                    })}
                  </div>
                </Fade>
              </div>

              <Fade right duration={2000}>
                <div className="skills-image-div">
                  <div {...title}>
                    {skill.title && (
                      <Fade right duration={1000}>
                        <h1
                          className="skills-heading"
                          style={{ color: theme.text, textAlign: "left" }}
                        >
                          {skill.title}
                        </h1>
                      </Fade>
                    )}
                  </div>
                  <GetSkillSvg fileName={skill.fileName} theme={theme} />
                </div>
              </Fade>
            </div>
          );
        }
      })}
    </div>
  );
}

export default SkillSection;
