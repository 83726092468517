import { Fade } from "react-reveal";
import { style } from "glamor";
import "./SkillCard.css";
const SkillCard = ({ skill, theme }) => {
  const styles = style({
    boxShadow: `0px 2px 5px ${skill.firstColor}`,
    border: `1px solid ${skill.firstColor}`,
    ":hover": {
      boxShadow: `0 5px 15px ${skill.secondColor}`,
    },
  });

  return (
    <Fade bottom duration={2000} distance="20px">
      <div className="skill-card" {...styles}>
        <div className="content">
          <div className="content-overlay"></div>
          <div
            className="skill-header"
            style={{
              background: `linear-gradient(92.02deg,${skill?.firstColor} 0.96%, ${skill?.secondColor} 100%)`,
            }}
          >
            <img
              className="logo_img"
              src={require(`../../assests/images/${skill?.imageUrl}`)}
              alt={skill?.title}
            />
          </div>
        </div>
        <div className="skill-body">
          <h2 className="skill-body-title" style={{ color: theme.text }}>
            {skill?.title}
          </h2>
        </div>
      </div>
    </Fade>
  );
};

export default SkillCard;
