/* Change this file to get your personal Porfolio */
import image26 from "./assests/images/isfahan-1400.jpg";
import image25 from "./assests/images/isfahan-relation.jpg";
import image24 from "./assests/images/urban-tech.jpg";
import image23 from "./assests/images/turquoise.jpg";
import image22 from "./assests/images/statistics.jpg";
import image15 from "./assests/images/15.JPG";
import image1 from "./assests/images/Announcing-the-satisfaction-of-the-association-of-service-counter-offices-with-the-performance-of-the-website-designed-for-the-association-min.jpg";
import image3 from "./assests/images/3.jpg";
import image16 from "./assests/images/Announcement-of-the-satisfaction-of-The-General-Directorate-of-Civil-Registry-of-Isfahan-province-with-the-performance-of-the-electronic-archive-program-of-documents-min.jpg";
import image18 from "./assests/images/Announcement-of-the-satisfaction-of-The-General-Directorate-of-Civil-Registry-of-Isfahan-province-with-the-performance-of-the-electronic-archive-program-of-documents1-min.jpg";
import imageRezayat from "./assests/images/Telecommunication-Company-of-Isfahan-province-satisfaction-with-the-performance-of-the-mobile-app-scanning-program-min.jpg";
import imageAbfa from "./assests/images/imageAbfa.jpg";
import imageIsfahan from "./assests/images/Fava.jpg";
import image2 from "./assests/images/2.jpg";
import image6 from "./assests/images/6.jpg";
import imageScan from "./assests/images/Notification_of_appointment_letter_to_the_board_of_trustees_of_The.jpg";
// Website related settings
const settings = {
  isSplash: false, // Change this to true if you want to use the splash screen.
  useCustomCursor: true, // Change this to false if you want the good'ol cursor
  googleTrackingID: "UA-174238252-2",
};

//Home Page
const greeting = {
  title: "AmirErfani.",
  title2: "Sepano Data Processing",
  logo_name: "AmirErfani",
  nickname: "hrishi1999 / picleric",
  full_name: "Sepano Data Processing",
  subTitle: "",
  link: "https://sepanodp.com/en/home-page-english/",
  // resumeLink:
  //   "https://drive.google.com/file/d/1IQge4pNhyj-RD2OOnEMrRPMODDUWeVDy/view?usp=sharing",
  mail: "mailto:hrishipatel99@gmail.com",
};

const socialMediaLinks = {
  /* Your Social Media Link */
  linkedin:
    "https://www.linkedin.com/in/data-pardazesh-sepano-%D8%AF%DB%8C%D8%AA%D8%A7%D9%BE%D8%B1%D8%AF%D8%A7%D8%B2%D8%B4-%D8%B3%D9%BE%D8%A7%D9%86%D9%88-352442202/",
  gmail: "Erfany.amir@gmail.com",
  twitter: "https://twitter.com/erfany?t=LkVMfSy20mvX3rSihxnTEQ&s=35",
  instagram: "https://instagram.com/amir_erfani83?igshid=MDM4ZDc5MmU=",
};

const skills = {
  data: [
    {
      title: "",
      fileName: "FullStackImg",
      skills: [
        "⚡ Born  in 1980",
        "⚡ Based in Isfahan, Iran",
        "⚡ Member of Isfahan province computer union organization",
        "⚡ Representative of Isfahan City Council in the Intelligence and Human Capital Committee",
        "⚡ Official member of Isfahan Entrepreneurship Center",
        "⚡ Speaker of Isfahan startup grind global event to support entrepreneurs by Google  ",
      ],
    },
    {
      title: "Education",
      fileName: "DataScienceImg",
      skills: [
        `⚡ Bachelor of Computer Science  from the Azad university of Isfahan `,
        `⚡ Withdrawal student of Master of Information Management from Utah State University, United States of America`,
      ],
    },
    {
      title: "Foundations & StartUps",
      fileName: "CloudInfraImg",
      skills: [
        "⚡ Founder & CEO of Sepano Data Processing (Since 2008 )",
        "⚡ Founder & CEO of Shipnow transportation system (Since 2016 )",
        "⚡ Founder & CEO of Mahsool.com (2005 - 2010 )",
      ],
    },
  ],
};

const degrees = {
  degrees: [
    {
      title: "Sepano Data Processing",
      logo_path: "logo sepano.svg",
      duration: "Since 2008",
      descriptions: [
        "⚡ The first rank of the country's organizational portals for four consecutive years",
        "⚡ Huge paperless and DMS water and sewage company projects in Isfahan, West Azarbaijan, Hamedan, etc",
        "⚡ Setting up  traffic control system and entry and exit security using IOT and combining with IA",
      ],
      website_link: "https://sepanodp.com/en/home-page-english/",
    },
    {
      title: "Shipnow tranportation system",
      logo_path: "logo shipnow.svg",
      alt_name: "SETI",
      duration: "Since 2016",
      descriptions: [
        "⚡ Job creation for 6000 drivers in the city of Isfahan until 2022",
        "⚡ 6000% sales growth in 5 years without any outer investment",
        "⚡ The most reliable and most used online transportation system in Isfahan",
      ],
      website_link: "http://shipnowdelivery.com/",
    },
  ],
};

const certifications = {
  certifications: [
    {
      title:
        "Isfahan Municipality's satisfaction with the design performance of Isfahan 1400 ",
      image: image26,
      certificate_link:
        "https://coursera.org/share/737a9587023c666b8e6cb303157aaeba",
    },
    {
      title:
        "Isfahan Municipality's satisfaction with the design performance of Isfahan municipality's public relations site",
      image: image25,
      certificate_link:
        "https://coursera.org/share/737a9587023c666b8e6cb303157aaeba",
    },
    {
      title:
        "Isfahan Municipality announces satisfaction with the performance of the international event design of new urban technologies",
      image: image24,
      certificate_link:
        "https://coursera.org/share/737a9587023c666b8e6cb303157aaeba",
    },
    {
      title:
        "Isfahan Municipality's satisfaction with the performance of application design and statistics site of Isfahan Municipality",
      image: image22,
      certificate_link:
        "https://coursera.org/share/737a9587023c666b8e6cb303157aaeba",
    },
    {
      title:
        "Isfahan Municipality's satisfaction with the performance of the application design and site of the turquoise planet of Isfahan Municipality",
      image: image23,
      certificate_link:
        "https://coursera.org/share/737a9587023c666b8e6cb303157aaeba",
    },
    {
      title:
        "Ranked first among portals and websites of all water and wastewater companies in the country",
      image: image15,
      certificate_link:
        "https://coursera.org/share/737a9587023c666b8e6cb303157aaeba",
    },
    {
      title:
        "Announcement of the satisfaction of The General Directorate of Civil Registry of Isfahan province with the performance of the electronic archive program of documents",
      image: image16,
      certificate_link:
        "https://www.coursera.org/account/accomplishments/verify/YLC25SJQKH3Y",
    },
    {
      title:
        "Announcement of the satisfaction of The General Directorate of Civil Registry of Isfahan province with the performance of the electronic archive program of documents",
      image: image18,
      certificate_link:
        "https://www.coursera.org/account/accomplishments/records/72KY93DT82MP",
    },
    {
      title:
        "Fava Municipality of Isfahan's satisfaction with the website of Isfahan Ziba newspaper",
      image: imageIsfahan,
      certificate_link: "https://graduation.udacity.com/confirm/HLE7K5V3",
    },
    {
      title:
        "Telecommunication Company of Isfahan province satisfaction with the performance of the mobile app scanning program",
      image: imageRezayat,
      certificate_link:
        "https://www.coursera.org/account/accomplishments/records/PKR9M9LQ3JWC",
    },
    {
      title:
        "Announcement of Isfahan province's water and wastewater company's satisfaction with the performance of the electronic Archive program of subscribers' files",
      image: imageAbfa,
      certificate_link: " ",
    },
    {
      title:
        "Announcing the satisfaction of the association of service counter offices with the performance of the website designed for the association",
      image: image1,
      certificate_link:
        "https://www.coursera.org/account/accomplishments/verify/FM5AKEZA9NUY",
    },
    {
      title:
        "Notification of appointment letter to the board of trustees of The Entrepreneurship Center of Isfahan Province",
      image: imageScan,
      certificate_link:
        "https://www.coursera.org/account/accomplishments/verify/FM5AKEZA9NUY",
    },
  ],
};

// Experience Page
const skillsPage = {
  title: "Skills",
  subtitle: "His own and his organizations skills and capabilities ",
  description:
    "I've completed two internships. I've mostly done projects on my own and I am actively looking for internships. I love organizing workshops to share my knowledge with others.",
  header_image_path: "experience.svg",
  managerSkills: [
    {
      title: "Leadership",
      imageUrl: "leader-ship.svg",
      firstColor: "#3498DB",
      secondColor: "#2E86C1",
    },
    {
      title: "Strategic Thinking",
      imageUrl: "strategic.svg",
      firstColor: "#E74C3C",
      secondColor: "#C0392B",
    },
    {
      title: "Financial Acumen",
      imageUrl: "financial.svg",
      firstColor: "#1ABC9C",
      secondColor: "#16A085",
    },
    {
      title: "Communication Skills",
      imageUrl: "comunicating.svg",
      firstColor: "#9B59B6",
      secondColor: "#8E44AD",
    },
    {
      title: "Operational Management",
      imageUrl: "opertional-management.svg",
      firstColor: "#F39C12",
      secondColor: "#D68910",
    },
    {
      title: "Innovation and Change Management",
      imageUrl: "innovation-management.svg",
      firstColor: "#27AE60",
      secondColor: "#229954",
    },
    {
      title: "Networking and Relationship Building",
      imageUrl: "networking.svg",
      firstColor: "#E67E22",
      secondColor: "#D35400",
    },
    {
      title: "Ethical Judgment and Integrity",
      imageUrl: "ethical-judgment.svg",
      firstColor: "#BDC3C7",
      secondColor: "#95A5A6",
    },
    {
      title: "Cultural Competence",
      imageUrl: "cultural.svg",
      firstColor: "#D35400",
      secondColor: "#A04000",
    },
    {
      title: "Technical and Industry Knowledge",
      imageUrl: "knowledge.svg",

      firstColor: "#34495E",
      secondColor: "#2C3E50",
    },
  ],
  sections: [
    {
      title: "Python programming language",
      imageUrl: "python.png",
      firstColor: "#47A048",
      secondColor: "#2F6A30",
    },
    {
      title: "Pandas",
      imageUrl: "pandas.png",
      firstColor: "#F6B808",
      secondColor: "#AD8106",
    },
    {
      title: "Tableau",
      imageUrl: "tableau.png",
      firstColor: "#2AAFED",
      secondColor: "#0E75A5",
    },
    {
      title: "Java programming language",
      imageUrl: "java.png",
      firstColor: "#E2405F",
      secondColor: "#C71E3F",
    },
    {
      title: "HTML5",
      imageUrl: "html.png",
      firstColor: "#BA7373",
      secondColor: "#934848",
    },
    {
      title: "Javascript programming language",
      imageUrl: "JS.png",
      firstColor: "#7A7A7A",
      secondColor: "#616161",
    },
    {
      title: "MySQL",
      imageUrl: "mysql.png",
      firstColor: "#6DC49B",
      secondColor: "#2C6D4E",
    },
    {
      title: "React",
      imageUrl: "react.png",
      firstColor: "#C5E2EE",
      secondColor: "#4EA6CB",
    },
    {
      title: "Docker",
      imageUrl: "docker.png",
      firstColor: "#FFC475",
      secondColor: "#FF990F",
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "These projects include works completely done by the companies under his management and works done in collaboration with other companies  ",
  avatar_image_path: "projects_image.svg",
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "erfani portfolio landing pic.png",
    description:
      "You can contact me through the social media provided below. I will try tou get back to you as soon as I can. ",
  },
  blogSection: {
    title: "Blogs",
    subtitle:
      "I don't blog frequently but when I do something awesome, I do try to document it so it can be helpful to others. I write on Medium.",
    link: "https://medium.com/@hrishipatel99",
    avatar_image_path: "blogs_image.svg",
  },
};

const projects = {
  data: [
    {
      name: "Ideation and implementation of national projects",
      url: "https://shipnow.ir/",
      url_name: "Shipnow",
      description: "Online transportation system active in Isfahan",
      imageUrl: "logo shipnow.svg",
    },
    {
      name: "Ideation and implementation of national projects",
      url: "https://rosegol.app/",
      url_name: "Rosegol ",
      description: "Online flower shop active in Isfahan",
      imageUrl: "rosegol.svg",
    },
    {
      name: "Ideation and implementation of national projects",
      url: "https://iranlocation.net/",
      url_name: "Iranlocation",
      description: "Online reservation of accommodation in Iran",
      imageUrl: "iranlocation.svg",
    },
    {
      name: "Consultation and implementation of sales projects",
      url: "http://dxbrealestatepro.com",
      url_name: "Dxbrealestatepro",
      description:
        "Online automation services for buying and selling property in Dubai",
      imageUrl: "dxb-min.png",
    },
    {
      name: "Consultation and implementation of sales projects",
      url: "http://www.hotel-hennies.com",
      url_name: "Hotel Hennies",
      description:
        "Online automation services for hotel reservations in Hanover",
      imageUrl: "hennies-hotel-min.png",
    },
    {
      name: "Consultation and implementation of sales projects",
      url: "https://eastbayimplant.com/",
      url_name: "Dental Implant Soakland",
      description: "Online automation services for dentistry in Oakland",
      imageUrl: "dental-implant-min.png",
    },
    {
      name: "Consultant and executive of international projects",
      url: "https://uconn.edu/",
      url_name: "University of Connecticut",
      description:
        "Consultant in the implementation of data reading of websites providing medical services (Data Crawling) in cooperation with the University of Connecticut in the United States",
      imageUrl: "cunneticet-university-min.png",
    },
    {
      name: "Consultant and executive of international projects",
      url: "https://wingswift.com",
      url_name: "Wingswift",
      description: "Executive of the WingSwift tourism website and application",
      imageUrl: "wingswift-min.png",
    },
    {
      name: "Consultant and executive of national projects",
      url: "https://www.drmaleki.ir",
      url_name: "Dr maleki",
      description:
        "Online automation services for cosmetologist office in Isfahan",
      imageUrl: "drmaleki500.png",
    },
    {
      name: "Consultant and Technical contractor",
      url: "http://trafficul.ir/",
      url_name: "Trafficul",
      description: "Advisor, implementer, supervisor and supporter",
      imageUrl: "isfahanMunicipality.svg",
    },
    {
      name: "Consultant and executive of national projects",
      url: "https://www.abfaesfahan.ir/",
      url_name: "Isfahan water and sewage",
      description:
        "Public survey system of Isfahan municipality website Implementation and support",
      imageUrl: "abfa-min.png",
    },
    {
      name: "Consultant and Technical contractor",
      url: "https://www.abfayazd.ir/",
      url_name: "Yazd water and sewage company",
      description: "Website design, implementation and support",
      imageUrl: "abfa-min.png",
    },
    {
      name: "Consultant and Technical contractor",
      url: "http://abfaazarbaijan.ir/",
      url_name: "Tabriz water and sewage company",
      description: "Website design, implementation and support",
      imageUrl: "abfa-min.png",
    },
    {
      name: "Consultant and Technical contractor",
      url: "https://ict.isfahan.ir/",
      url_name: "FAVA organization of Isfahan municipality",
      description: "Website design, implementation and support",
      imageUrl: "fava2.png",
    },
    {
      name: "Consultant and Technical contractor",
      url: "https://ea.tci.ir/",
      url_name: "Tabriz Telecommunication Company",
      description: "Electronic archive system and support",
      imageUrl: "mokhaberat.svg",
    },
    {
      name: "Consultant and executive of national projects",
      url: "http://isfahanrasad.ir/",
      url_name: "Observatory",
      description:
        "Isfahan City Observatory website Implementation and support",
      imageUrl: "logo rasad.png",
    },
    {
      name: "Consultant and Technical contractor",
      url: "https://isfahan.ir/",
      url_name: " Isfahan province municipality",
      description: "Electronic archive system and support",
      imageUrl: "isfahanMunicipality.svg",
    },
    {
      name: "Consultant and Technical contractor",
      url: "https://hamedan.ir/",
      url_name: "Hamedan province municipality",
      description: "Electronic archive system and support",
      imageUrl: "Hamedan_Municipality.svg",
    },
    {
      name: "Consultant and executive of national projects",
      url: "https://www.drmaleki.ir",
      url_name: "Hoor App",
      description:
        "Online automation and reservation services for cosmetologist office in Isfahan",
      imageUrl: "drmaleki500.png",
    },
    {
      name: "Ideation and implementation of national projects",
      url: "https://duvitacrm.com",
      url_name: "Duvita",
      description:
        "Online software designed for customer and sales growth and management.",
      imageUrl: "dovita.png",
    },
    {
      name: "Ideation and implementation of national projects",
      url: "https://shipshopapp.ir/",
      url_name: "Shipshop",
      description: "Online shop active in Isfahan",
      imageUrl: "shipshop logo.svg",
    },
  ],
};

export {
  settings,
  greeting,
  socialMediaLinks,
  skills,
  degrees,
  certifications,
  skillsPage,
  projectsHeader,
  contactPageData,
  projects,
};
